<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new_m", {
                                m: $t("message.vacation_planes"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="closeModal(true)"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 type-expensies" :class="mode ? 'table__myday' : 'table__mynight'">
                  <el-form
                  ref="form"
                  status-icon
                  :model="form"
                  :rules="rules"
                  >
                  <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item :class="mode ? 'input__day' : 'input__night'" >
                          <span class="input--label d-block mb-2 text__day2" :class="mode ? 'text__day2' : 'text__night2'">
                            <span class="start_top">*</span>
                            {{ $t("Период Год") }}
                          </span>
                          <el-date-picker
                            v-model="form.year"
                              type="year"
                              :size="'small'"
                              :format="'yyyy'"
                              :value-format="'yyyy'"
                              :picker-options="pickerOptions"
                              style="width:100%">
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? 'input__day' : 'input__night'" >
                          <select-or-hidden-company  :id="form.branch_id" v-model="form.branch_id">
                          </select-or-hidden-company>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectStaff from "@/components/filters/inventory/select-staff";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import selectOrHiddenCompany from '@/components/filters/inventory/select-or-hidden-company.vue';
export default {
    mixins: [form, drawer],
    name: "RegionController",
    components:{
      selectStaff,
      selectOrHiddenCompany
    },
    watch: {
    "form.staff_id": {
      handler: function (e) {
        this.reportConsole(e)
      },
      immediate: true,
      deep: true,
    },
  },
    data() {
        return {
          staff:this.selecedStaff,
          pickerOptions: { 
            disabledDate(time) {
                  return new Date(time).getYear() === '2022';
              }
          }
        };
    },
    mounted(){
      this.staff=null;
    },
    created() {
      this.staff=null;
     
    },
    computed: {
        ...mapGetters({
            rules: "vacationPlane/rules",
            model: "vacationPlane/model",
            columns: "vacationPlane/columns",
            mode:"MODE"
        }),
    },
    unmounted(){
      console.log('after');
    },
    methods: {
        ...mapActions({
            save: "vacationPlane/store",
            getStaffInfo: "vacationPlane/getStaffInfo",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.closeModal();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        reportConsole(id){
          if (id) {
            this.getStaffInfo(id).then((res)=>{
              this.staff = res.data.result.data.staff;
              console.log( );
            // console.log((new Date(res.data.result.data.staff.hiring_date)).getTime());
              this.pickerOptions={
                disabledDate(time) {
                        return time.getTime() < Date.parse(res.data.result.data.staff.to_date) - 8.64e7;
                    }
              }
              console.log(this.pickerOptions);
            });            
          }
        },
        closeModal(close=true){
          this.staff=null;
          if (close) this.close();
        }
    },
};
</script>

<style lang="scss">
  .type-expensies{
    .el-textarea__inner{
      min-height: 60px !important;
    }
  }
  .staff_info{    
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #DCDFE6;
    padding: 5px 10px;
    border-radius: 5px;
    .staff_info_item{
      padding: 5px 0;
      width: 50% !important;
    }

  }
</style>

